<template>
<section class="admin-section ">
    <div class="app-main__inner contactUs">
      <div class="row">
          <div class="col-md-12 col-sm-12 fullwidth">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-chat icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Contact Us</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 fullwidth">
            <div class="main-card mb-3 card">
                <div class="card-body">
                  <div class="container contact-form">
                    <form @submit.prevent="saveUserMessageToDB">
                        <h3 class="mb-3 text-info">Drop Us a Message</h3>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 fullwidth">
                            <div class="form-group">
                                <input type="text" v-model="userMessage.first_name" name="txtfName" class="form-control" placeholder="Your First Name *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.last_name" name="txtlName" class="form-control" placeholder="Your Last Name" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.email" name="txtEmail" class="form-control" placeholder="Your Email *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.mobileNumber" name="txtMobileNumber" class="form-control" placeholder="Your Mobile Number *" value="" />
                            </div>
                        </div>
                        <div class="pl-2 col-md-6 col-sm-6">
                            <div class="form-group">
                                <textarea v-model="userMessage.message" maxlength="500" name="txtMsg" class="form-control" placeholder="Your Message *" style="width: 100%; height: 150px!important"></textarea>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6 fullwidth">
                          <div class="form-group pull-right">
                              <input type="submit" name="btnSubmit" class="btn btn-success" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 fullwidth">
            <div class="main-card mb-3 card">
                <!-- <div class="card-body">
                  <GMapMap :center="center" :zoom="7" map-type-id="terrain" style="width: 500px; height: 300px">
                    <GMapCluster>
                      <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position"/>
                    </GMapCluster>
                  </GMapMap>
                </div>
                -->
                <div class="card-body contact-form">
                    <h3 class="mb-3 text-info">Google Maps</h3>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.9750374486384!2d144.95577875132452!3d-37.81405367965288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d24368cb0cf%3A0xd6106507ccea696e!2sPTE%20Classes%20-%20PTE%2C%20NAATI%20CCL%2C%20IELTS%20coaching%20and%20training!5e0!3m2!1sen!2sau!4v1620649526930!5m2!1sen!2sau" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6303.883983072372!2d144.96177357802915!3d-37.81482769855116!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x65f4b13fbcbb38ba!2sThink%20Higher%20Consultants%20-%20Education%20and%20Migration%20Consultants!5e0!3m2!1sen!2sau!4v1615936396768!5m2!1sen!2sau" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                </div>
            </div>
        </div>
    </div>
  </div>
</section>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import unirest from "unirest"
export default {
  components: {
  },
  data () {
    return {
      center: {
        lat: 51.093048,
        lng: 6.842120
      },
      userMessage:{
        user_id:0,
        first_name:'',
        last_name:'',
        email:'',
        message:'',
        mobileNumber:'',
        url:'users/createUserMessage'
      },
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          }
        }
      ]
    }
  },
  methods:{
    ...mapActions({
            saveUserMessage:'auth/saveUserPersonalInfo'
        }),
        ...mapGetters({
            getUserMessages: 'auth/getPersonalInfo'
        }),
    saveUserMessageToDB(){
      if(this.userMessage.first_name.trim()==''| this.userMessage.message.trim()==''|this.userMessage.email.trim()==''|this.userMessage.mobileNumber.trim()==''){
        return this.$alert('Please fill all the required fields');
      }
      else{
        this.$alert('You message received, our Team will contact you shortly.')
        
        this.saveUserMessage(this.userMessage).then(()=>{
          this.sendUserMessage(this.userMessage.mobileNumber,this.userMessage.firstName,this.userMessage.userId);
          let melbOffice="+61435600610";
          this.sendMessageOffice(melbOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          let pakOffice="+923303153327";
          this.sendMessageOffice(pakOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          this.userMessage.message=''
        })
      }
    },
    sendUserMessage(mobileNumber,firstName,userId){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": "Thank You! We have received your query and our concerned department will get back to you shortly",
                  "Purpose": "PTE User Query response",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    },
    sendMessageOffice(mobileNumber,firstName,userId,msg){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": msg,
                  "Purpose": "PTE Query to Office",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    }
  },
  created(){
    //debugger
    const user=this.$ls.get('user')
    this.userMessage.user_id=user.user_id
    this.userMessage.first_name=user.first_name
    this.userMessage.last_name=user.last_name
    this.userMessage.email=user.email_id
    this.userMessage.mobileNumber=user.toNumber
  }
}
</script>
